import React, { useEffect, useState} from "react"
import { Row, Col, Collapse, Modal, Flex, Form, Input, Typography, Select, Spin, Button,Image, message, Tooltip,Badge ,Divider  } from "antd"
import CpfCnpj from "@phpenterprise/cpf-cnpj-mask"
import Mask from 'react-input-mask'

//componets
import Header from "../components/header"
import Footer from "../components/footer"
import {checkDoc, getWindowParams, generateToken, mask} from '../../helper'
import config from 'config/config'

// images
import LOGO from "../../assets/img/logo.png"
import NOVOUSUARIOSM from "../../assets/img/esqueceu-150x150.png"
import NOVOUSUARIOLG from "../../assets/img/esqueceu.png"

const { Title, Paragraph, Text, Link} = Typography;


const Home = (props) => {
    const [form] = Form.useForm();
    const [formSms] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)
    const [etapa, setEtapa] = useState('CADASTRO')
    const [token] = useState(generateToken())
    const [code, setCode] = useState()
    const [dataForm, setDataForm] = useState()

    useEffect(() => {
    },[])

  

    

    const timerResend = () => {
        setResendLoading(true);
        setTimeout(() => {
            setResendLoading(false);
        }, 25000);
    }

    
    

    
    /***
     *    ███████  ██████  ██████  ███    ███ 
     *    ██      ██    ██ ██   ██ ████  ████ 
     *    █████   ██    ██ ██████  ██ ████ ██ 
     *    ██      ██    ██ ██   ██ ██  ██  ██ 
     *    ██       ██████  ██   ██ ██      ██ 
     *        
     *    AREA: formulario                                
     */
    const FormOprdr = () => {
        return <Spin spinning={loading}>
            <Form
                form={form}
                onFinish={onFinishForm}
                autoComplete="off"
                initialValues={{
                    cnpj: getWindowParams().cnpj,
                    cpf: getWindowParams().cpf,
                    // cpf: '11111111111',
                }}
            >
                <Title level={2}>Preencha as informaçoes abaixo</Title>
                <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >

                    <Divider orientation="left">Dados Empresa</Divider>
                        <Form.Item
                            className="form-control"
                            name="cnpj"
                            hasFeedback
                            label={ <>
                                CPF/CNPJ Empresa
                                <Tooltip placement="topLeft" title={'Empresa registrada no BSF-direto.'}>
                                    <Badge style={{marginLeft:5}} text count='?' size="small"/>
                                </Tooltip>
                            </> }
                            rules={[
                                { required: true   , message: "CNPJ é requerido."},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        let check = checkDoc(getFieldValue('cnpj'),['cpf','cnpj'])
                                        if ( !value || typeof check !== 'string' ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(check));
                                    },
                                }),
                            ]}
                        >
                                <CpfCnpj className="ant-input ant-input-outlined" />
                        </Form.Item>
                    </Col>
                    
                    <Divider orientation="left">Dados do Operador</Divider>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Form.Item
                            className="form-control"
                            name="cpf"
                            label="CPF"
                            rules={[
                                { required: true, message:"CPF é requerido."},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    let check = checkDoc(getFieldValue('cpf'),['cpf'])
                                    if ( !value || typeof check !== 'string' ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(check));
                                    },
                                }),
                            ]}
                        >
                            <CpfCnpj className="ant-input ant-input-outlined" maxLength={14} />
                        </Form.Item>
                    </Col>
                    

                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Form.Item className="text-right form-control" >
                            <Button htmlType="submit">Enviar</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form> 
        </Spin>
    }
    const onFinishForm = async (e) => {
        // setDataForm(e)
        let url = `${config[process.env.NODE_ENV].int}/empresa/operador/${form.getFieldValue('cnpj').replace(/\D/g,"")}/${form.getFieldValue('cpf').replace(/\D/g,"")}`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `${token}`
                },
            })

            if( !response.ok ){
                let json = await response.json()
                message.error( json.message)
                setLoading(false)
                return false
            }

            let json = await response.json()
            setDataForm(json.content)
            onSendSMS(json.content)
            setLoading(false)
            
        }catch(e){
            console.log(e)
            setLoading(false)
            message.error("Problemas de comunicação com o servidor.")
        }
    }



    /***
     *    ███████ ███    ███ ███████ 
     *    ██      ████  ████ ██      
     *    ███████ ██ ████ ██ ███████ 
     *         ██ ██  ██  ██      ██ 
     *    ███████ ██      ██ ███████ 
     *                                 
     *    AREA: SMS                           
     */
    const CheckSMS = () => {
        

        return <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                <Form
                    form={formSms}
                    onFinish={onFinishSMS}
                    autoComplete="off"
                    initialValues={{
                        // sms_code: '123123'
                    }}
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                            <Input className="" name="id" type="hidden" value="id0"/> 
                            <Input className="sms_verify id0" name="sms_verify" type="hidden"/> 
                            <Input className="sms_protocolo" name="protocolo" type="hidden"/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                            <Title level={4} align="center">
                                Para confirmar o seu cadastro, informe abaixo o código que foi enviado para
                            </Title>
                            <Title level={4} align="center">
                                {mask(mask(dataForm.telefone,"tel"),"*")}
                            </Title>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                            <Paragraph>
                                Essa validação pode demorar alguns segundos até o recebimento.
                            </Paragraph>
                            <Paragraph>
                                Enviamos o código de ativação no seu celular: <b><span id="sms_cell_mask"></span></b><br/>
                            </Paragraph>
                        </Col>
                        <Col push={6} xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} >
                            <Form.Item
                                className="form-control"
                                name="sms_code"
                                rules={[
                                    // CÓDIGO INVÁLIDO
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            let check = getFieldValue('sms_code').replace(/\D/g,"")
                                            if ( check.length !== 6) {
                                                return Promise.reject(new Error('CÓDIGO INVÁLIDO'));
                                            }
                                            return Promise.resolve();
                                        }
                                    }),
                                ]}
                            >
                                <Mask mask="9 9 9 - 9 9 9" style={{textAlign:"center",fontSize: 30}}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center " }>
                            <Form.Item className="form-control">
                                <Button htmlType="submit">Enviar</Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center " }>
                            <Divider/>
                            <Form.Item className="form-control" >
                                    <Button loading={resendLoading} type="button" onClick={() => resendSMS()}>Reenviar</Button>
                            </Form.Item>
                            <Form.Item className="form-control" >
                                <Button type="button" onClick={() => { formSms.resetFields(); setResendLoading(false); setEtapa('CADASTRO') }}>Voltar ao dados</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>


            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <div className="tryChangeYourCel" style={{ display: 'none', color: 'darkred'}} bis_skin_checked="1">
                    Notamos que foram solicitadas várias vezes o reenvio do código de ativação, porém sem resultados,
                    se estiver com problemas em validar e/ou preferir solicite a troca do celular e tente novamente.
                </div>
            </Col>
        </Row>
    }
    const onSendSMS = async (e) => {
        // setDataForm(e)
        let url = `${config[process.env.NODE_ENV].int}/sms`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `${token}`
                },
                body: JSON.stringify({
                    "lista": [
                        {
                            "origem": "BSFDIRETO",
                            "titulo": "Envio codigo operador",
                            "texto": "BSFDIRETO: seu codigo de verificacao e ",
                            "email": e.email,
                            "celular": e.telefone.replace(/\D/g,''),
                            "type": "code"
                        }
                    ]
                })
            })

            if( !response.ok ){
                let json = await response.json()
                message.error( "Problemas na validação do Telefone, tente mais tarde.")
                setLoading(false)
                return false
            }

            let json = await response.json()
            if(json.content.group_id){
                setCode(json.content.group_id)
                setDataForm(e) //armazenando dados do usuario caso encontrado
                timerResend()
                setEtapa("SMS")
                setLoading(false)
                return true
            }
            throw new Error("Problemas de comunicação com o servidor.");
            
        }catch(e){
            console.log(e)
            setLoading(false)
            message.error("Problemas de comunicação com o servidor.")
        }
    }
    const resendSMS = async (e) => {
        // setDataForm(e)
        let url = `${config[process.env.NODE_ENV].int}/sms`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": `${token}`
                },
                body: JSON.stringify({
                    "lista": [
                        {
                            "origem": "BSFDIRETO",
                            "titulo": "Envio codigo operador",
                            "texto": "BSFDIRETO: seu codigo de verificacao é ",
                            "email": form.getFieldValue('email'),
                            "celular": form.getFieldValue('telefone'),
                            "type": "code"
                        }
                    ]
                })
            })

            if( !response.ok ){
                let json = await response.json()
                message.error( "Problemas na validação do Telefone, tente mais tarde.")
                setLoading(false)
                return false
            }

            let json = await response.json()
            if(json.content.group_id){
                setCode(json.content.group_id)
                timerResend()
                setLoading(false)
                return true
            }
            throw new Error("Problemas de comunicação com o servidor.");
        }catch(e){
            console.log(e)
            setLoading(false)
            message.error("Problemas de comunicação com o servidor.")
        }
    }
    /**
     * @param {*} sms_code
     * @returns 
     */
    // let sms_code = formSms.getFieldValue(['sms_code']).replace(/\D/g,"")
    const onFinishSMS = async (e) => {
        let url = `${config[process.env.NODE_ENV].int}/sms?group_id=${code}`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "GET",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `${token}`
                },
            })

            if( !response.ok ){
                let json = await response.json()
                message.error( "Não foi possivel validar o codigo SMS")
                setLoading(false)
                return false
            }
            
            let json = await response.json()
            console.log(json.content[0].text_sms_envo.replace(/\D/g,''), e.sms_code.replace(/\D/g,''))
            if(json.content[0].text_sms_envo.replace(/\D/g,'') == e.sms_code.replace(/\D/g,'')){
                setLoading(false)
                setEtapa('PASSWORD')
                return true
            } 
            message.error( "Cosigo de validação não confere!")
            setLoading(false)
            return false
        }catch(e){
            setLoading(false)
            message.error("Problemas de comunicação com o servidor.")
        }
    }



    /***
     *    ███████ ███████ ███    ██ ██   ██  █████  
     *    ██      ██      ████   ██ ██   ██ ██   ██ 
     *    ███████ █████   ██ ██  ██ ███████ ███████ 
     *         ██ ██      ██  ██ ██ ██   ██ ██   ██ 
     *    ███████ ███████ ██   ████ ██   ██ ██   ██ 
     *                                              
     *    AREA: senha                                          
     */
    const Password = () => {
        return <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
            <Form
                form={formSms}
                onFinish={onPasswordFinish}
                autoComplete="off"
                initialValues={{
                    // sms_code: '123123'
                }}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                        <Input className="" name="token" type="hidden" value={dataForm.token}/> 
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center "}>
                        <Title level={4} align="center">
                            Informe a nova senha!
                        </Title>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                            className="form-control"
                            name="password"
                            label="Senha"
                            rules={[
                                {
                                    required: true,
                                    message: 'Informe a senha.',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if ( !value || !( getFieldValue('password').length < 8 )  ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A senha deve ter entre 8 e 16 caracteres.'));
                                    },
                                }),
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if ( !value || !( getFieldValue('password').length > 16 )  ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A senha deve ter entre 8 e 16 caracteres.'));
                                    },
                                }),
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    let regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/
                                    if ( !value || ( getFieldValue('password').length < 8 ) || (regex.test(getFieldValue('password'))) ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A senha deve conter numeros, letras maiusculas e minusculas '));
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>

                        <Form.Item
                            className="form-control"
                            name="confirm"
                            label="Confirmar"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirme sua senha.',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('A confirmação não confere!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-right " }>
                        <Form.Item className="form-control">
                            <Button htmlType="submit">Enviar</Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className={"text-center " }>
                        <Divider/>
                        <Form.Item className="form-control" >
                            <Button type="button" onClick={() => { formSms.resetFields(); setResendLoading(false); setEtapa('CADASTRO') }}>Cancelar</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Col>


        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className="tryChangeYourCel" style={{ display: 'none', color: 'darkred'}} bis_skin_checked="1">
                Notamos que foram solicitadas várias vezes o reenvio do código de ativação, porém sem resultados,
                se estiver com problemas em validar e/ou preferir solicite a troca do celular e tente novamente.
            </div>
        </Col>
    </Row>  
    }
    const onPasswordFinish = async (e) => {
        console.log({
            ...e,
            ...dataForm
        })
        let url = `${config[process.env.NODE_ENV].int}/empresa/operador/${dataForm.token}/upd`
        setLoading(true)
        try{
            let response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `${token}`
                },
                body: JSON.stringify({
                    ...e
                })
            })

            if( !response.ok ){
                let json = await response.json()
                if(json.status == false){
                    throw json.message
                }
                message.error( "CNPJ ou CPF não localizado")
                setLoading(false)
                return false
            }

            let json = await response.json()
            setEtapa('SUCESSO')
            return true
        }catch(e){
            if(typeof e == 'object'){
                console.log(e)
                message.error("Falha em criar operador, caso o problema continuar entre em contato via Whatsapp")
                return false
            }
            message.error(e)
        }finally{
            setLoading(false)
        }
    }

    /***
     *    ███████ ██    ██  ██████ ███████ ███████ ███████ 
     *    ██      ██    ██ ██      ██      ██      ██      
     *    ███████ ██    ██ ██      █████   ███████ ███████ 
     *         ██ ██    ██ ██      ██           ██      ██ 
     *    ███████  ██████   ██████ ███████ ███████ ███████ 
     *                                                     
     *    AREA: sucesso                                                
     */
    const SucessoReturn = () => {
        return <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"center"}}>
                <Title level={3} align="left">
                    Operador atualizado com sucesso!
                </Title>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"center"}}>
                <Title level={4} align="left">
                    Sua senha foi alterada com sucesso, efetue o login utilizando o formulário localizado na parte superior da página.
                </Title>
                <Title level={4} align="left">
                    <Link href="/">
                        <Button htmlType="button">Voltar a página principal</Button>
                    </Link>
                </Title>
            </Col>
        </Row>
    }

    
    return <>
        <Header/>
        <main>
            {/* ICONES COM BENEFICIOS */}
            <section className="cadastro">
                <div className="wrapper">
                    <h1 className="title small">
                        SOLICITAR NOVA SENHA
                    </h1>
                    <Spin spinning={loading}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32, }}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="animated fadeIn">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{textAlign:"center"}}>
                                        <Image
                                            width={150}
                                            src={NOVOUSUARIOSM}
                                            preview={false}
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{textAlign:"center"}}>
                                        <Title level={4} align="left">
                                            Para maior segurança nas transações de dados em nosso site, estamos disponibilizando o sistema Acesso Protegido.
                                        </Title>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"center"}}>
                                        <Title level={4} align="left">
                                            Com esse tipo de acesso, apenas usuários previamente cadastrados poderão visualizar e gerenciar as informações.
                                        </Title>
                                        <Title level={4} align="left">
                                            Para prosseguir com o acesso cadastre o usuário,
                                        </Title>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"center"}}>
                                        <Divider/>
                                        <Title level={4} align="left">
                                            Para retornar a página principal clique em voltar
                                        </Title>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign:"right"}}>
                                        <Link href="/"><Button htmlType="button" type="primary">VOLTAR</Button></Link>
                                    </Col>
                                </Row>
                                <br/>
                            </Col>
                            
                            {etapa == "CADASTRO" ? 
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className={"animated fadeIn"}>
                                    <FormOprdr/>
                                </Col>
                            : ""}
                            {etapa == "SMS" ? 
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="">
                                    <CheckSMS/>
                                </Col>
                            : ""}
                            {etapa == "PASSWORD" ? 
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="">
                                    <Password/>
                                </Col>
                            : ""}
                            {etapa == "SUCESSO" ? 
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="">
                                    <SucessoReturn/>
                                </Col>
                            : ""}
                        </Row>
                    </Spin>
                </div>
            </section>
        </main>
        <Footer/>
    </>
}

export default Home