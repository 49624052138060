import React, { useState } from "react"
import { Row, Col, Modal } from "antd"
import LGPD from "./lgpd"

const Footer = () => {
    const [open, setOpen] = useState(false)

    return <>
        <footer className="rodape">
            <div className="wrapper">
                <Row gutter={10}>
                    <Col sm={24} lg={6} className="go-100">
                        <div>
                            <a onClick={() => setOpen(true)}>Termos de Uso</a>
                        </div>
                    </Col>
                    <Col sm={24} lg={18}>
                        <div className="direitos">
                            © 2015 - 2024 • Benefício Social Familiar. Todos os direitos reservado
                        </div>
                    </Col>
                </Row>
            </div>
        </footer>

        <Modal
            open={open}
            onClose={() =>  setOpen(false)}
            onCancel={() =>  setOpen(false)}
            footer={null}
            width={"95%"}
            style={{
                width: "100%",
                maxWidth: 1100
            }}
        >
            <LGPD />
        </Modal>
    </>

   
}

export default Footer