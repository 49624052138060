import React from "react"

const LGPD = () => {
    return <div id="content" className="site-main post-3 page type-page status-publish hentry">

        <header className="page-header">
            <h1 className="entry-title">Privacy Policy</h1>		
        </header>

        <div className="page-content">

            <h3 className="wp-block-heading">LGPD</h3>



            <p>Para lisura e transparência do processo de prestação dos benefícios, seguem abaixo informações de seu interesse, em cumprimento às previsões legais.</p>



            <p>Lei n. 13.709/2018 – Lei Geral de Proteção de Dados Pessoais – LGPD</p>



            <p><a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm">http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm</a></p>



            <p><strong>POLÍTICA DE PRIVACIDADE</strong></p>



            <p>Nos preocupamos com seus Dados Pessoais &#8211; Política de Privacidade BSF</p>



            <p>O Benefício Social Familiar, desenvolveu a presente Política de Privacidade com base na Lei Geral de Proteção de Dados (LGPD), a fim de comunicar de forma transparente aos seus clientes, parceiros, prestadores de serviços e terceiros (doravante denominados em conjunto&nbsp; como&nbsp;<strong>“Usuário”</strong>), as regras gerais de coleta, uso, armazenamento, tratamento, proteção, compartilhamento e exclusão de dados.</p>



            <p>A presente Política de Privacidade é aplicável a esse site e ao aplicativo&nbsp;<strong>BSF ONLINE</strong>.</p>



            <p>Caso o Usuário não concorde com a presente Política, o mesmo deve se abster de utilizar o site ou o aplicativo.</p>



            <p><strong>1. O que é LGPD?</strong></p>



            <p>É uma lei federal que estabelece regras ao uso de dados pessoais por entidades públicas e privadas. A LGPD garante direitos aos titulares dos dados e estabelece a regra para coleta, armazenamento, processamento e compartilhamento de dados pessoais de pessoas físicas e jurídicas.</p>



            <p><strong>2. Quais serão os dados coletados?</strong></p>



            <p>O Benefício Social Familiar poderá coletar e utilizar diversos dados dos Usuários durante a contratação de serviços oferecidos e produtos, e somente para esta finalidade, os quais podem ser classificados em três categorias:&nbsp;<strong>(i)</strong>&nbsp;<strong>DADOS PESSOAIS</strong>&nbsp;(dados que trazem elementos que identificam – ou podem identificar – um indivíduo);&nbsp;<strong>(ii)</strong>&nbsp;<strong>DADOS FINANCEIROS</strong>&nbsp;(dados que trazem informações bancárias e/ou relativas ao sistema financeiro nacional); e&nbsp;<strong>(iii)</strong>&nbsp;<strong>DADOS SENSÍVEIS</strong>&nbsp;(dados que, além de identificar uma pessoa, revelam elementos relacionados a sua saúde e/ou genética, por exemplo, dentre outros).</p>



            <p>Durante o processo de contratação dos serviços ou produtos ou no decorrer da prestação de um de seus serviços, o Benefício Social Familiar poderá obter dados adicionais por meio de fontes públicas e/ou de terceiros, inclusive, dados fornecidos espontaneamente pelos Usuários através de sites e aplicativos indicados pelo Benefício Social Familiar.</p>



            <p>Com a aceitação da Política pelo Usuário, este concorda e permite o acesso aos seus dados a partir do seu primeiro acesso ou utilização do site ou do aplicativo, para o fim específico da prestação dos benefícios prestados.</p>



            <p><strong>3. Aceitação da coleta de dados</strong></p>



            <p>Ao aceitar essa Política, o Usuário reitera que todos os seus dados foram coletados mediante o seu consentimento livre, voluntário, informado e livre de dúvidas e/ou ambiguidades, para a consecução de um objetivo específico, explícito e com finalidade específica da prestação dos benefícios.</p>



            <p>O Benefício Social Familiar necessita dos dados pessoais dos Usuários caso seja de seu interesse em solicitar a prestação de serviços ou produtos (como exemplo, o endereço de entrega de cestas básicas na prestação de benefícios). Caso o Usuário não concorde em fornecer seus dados, o Benefício Social Familiar estará impossibilitado de atender e prestar os benefícios.</p>



            <p><strong>4. Uso dos dados</strong></p>



            <p>O Benefício Social Familiar poderá utilizar os dados coletados para o propósito específico de prestação dos benefícios vigentes.</p>



            <p>• Facilitar a identificação do perfil e necessidades dos Usuários;</p>



            <p>• Cumprir outras obrigações legais;</p>



            <p>• Obter relatórios de empresas pagantes, número de trabalhadores, índice de inadimplência, variação do contingente de trabalhadores, novas empresas, empresas inativadas, cadastro geral de empresas, mapeamento de base territorial, número de natalidades, trabalhadores incapacitados permanentemente, e&nbsp;<em>causa mortis</em>, além personalização dos seus serviços e/ou produtos, para que se ajustem às necessidades dos Usuários.&nbsp;</p>



            <p>O Benefício Social Familiar, no estrito cumprimento da prestação dos benefícios, poderá compartilhar seus dados com:</p>



            <p>• Autoridades públicas (por exigências previstas na legislação aplicável), empresas do Grupo BSF, e outras partes relacionadas, quando estritamente necessário à prestação dos benefícios vigentes;</p>



            <p>• Prestadores de serviços que realizam operações ao Grupo BSF devem se comprometer a proteger os dados com eles compartilhados;</p>



            <p>O Benefício Social Familiar adota medidas técnicas de proteção dos dados pessoais, dentre as quais, destaca-se:</p>



            <p>• Estabelecimento de controle restrito sobre o acesso aos dados mediante a definição de responsabilidades das pessoas que terão possibilidade de acesso e de privilégios mínimos de acesso exclusivo para determinados responsáveis;</p>



            <p>• Estabelecimento de mecanismos de autenticação de acesso aos registros;</p>



            <p>• Uso de soluções de gestão dos registros por meio de técnicas que garantam a inviolabilidade dos dados, como encriptação;</p>



            <p>• Utilização de meios para proteção dos dados em repouso/armazenado;</p>



            <p><strong>5.&nbsp; Armazenamento e exclusão</strong></p>



            <p>Os dados serão armazenados pelo menor tempo necessário (para a prestação dos benefícios vigentes), observando requisitos legais e regulatórios.</p>



            <p>A exclusão dos dados se dará de forma definitiva no prazo julgado conveniente pelo Benefício Social Familiar, desde que observados os requisitos legais e regulatórios, e nos limites da prestação dos benefícios.</p>



            <p><strong>6.&nbsp; Tratamento e Transferência de Informação</strong></p>



            <p>A utilização dos dados será limitada aos negócios realizados e seu uso restrito às partes interessadas, responsáveis pela entrega dos serviços e produtos contratados.</p>



            <p>Sempre que os dados forem transferidos para outras empresas do Grupo BSF, visando tão e somente a prestação dos benefícios vigentes, a transferência se dará de acordo com as normas legais e regulatórias vigentes, com o máximo grau de confidencialidade e zelo, no entanto, em razão da própria natureza da Internet, não é possível garantir que terceiros mal-intencionados não logrem sucesso em acessar indevidamente as informações armazenadas pelo Benefício Social Familiar.</p>



            <p><strong>7.&nbsp; Direito e Deveres do Usuário</strong></p>



            <p>Quando autorizado pelas leis aplicáveis, o Usuário poderá, por meio dos canais oficiais de comunicação do Benefício Social Familiar, solicitar limitado à prestação dos serviços vinculados aos benefícios:</p>



            <p>• A verificação dos seus dados pessoais;</p>



            <p>• A revogação/ restrição de um consentimento anterior a qualquer momento;</p>



            <p>• Cópia dos seus dados pessoais;</p>



            <p>• A correção dos dados pessoais que apresentem qualquer imprecisão;</p>



            <p>É de responsabilidade exclusiva do Usuário:</p>



            <p>• Inserir somente informações verídicas no site ou aplicativo do Benefício Social Familiar;</p>



            <p>• Zelar pelo sigilo de seus logins e senhas sempre que acessar a Internet e perante terceiros;</p>



            <p>• Cumprir com as disposições contidas nesta Política.</p>



            <p><strong>8.&nbsp; Legislação e foro competente</strong></p>



            <p>Esta Política será regida, interpretada e executada de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro de Americana, estado de São Paulo, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento. O Usuário consente, expressamente, com a competência desse juízo, e renúncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</p>



            <p><strong>9.&nbsp; Fale Conosco</strong></p>



            <p>Em caso de dúvidas, solicitações em relação à Lei Geral de Proteção de Dados (LGPD) ou sobre a nossa Política de Privacidade, entre em contato pelos canais oficiais de comunicação do Benefício Social Familiar informados abaixo:</p>



            <p>E-mail: atendimento@beneficiosocial.com.br</p>



            <p>Telefones: SAC: 0800 773 3738 e 0800 580 3738 (atendimento 24 horas)</p>



            <p>Atualizado em&nbsp;<strong>28/10/2020</strong>&nbsp;&#8211; versão&nbsp;<strong>1.00 / 2020</strong></p>



            <hr className="wp-block-separator has-alpha-channel-opacity" />



            <p>POLÍTICA DE COOKIES</p>



            <p><strong>POLÍTICA DE COOKIES</strong></p>



            <p>O Benefício Social Familiar agradece sua visita ao nosso website e o seu interesse em nossos serviços e produtos. Sua privacidade é importante para nós e queremos que você se sinta confortável visitando nosso site. Todos os dados pessoais coletados, processados e usados durante visitas ao site são tratados dentro das normas e legislações vigentes.</p>



            <p>Ao acessar o site Benefício Social Familiar você aceita esta Política de Privacidade. Se você não concorda com esta declaração, interrompa imediatamente o seu acesso ao website do Benefício Social Familiar, pois, a sua permanência neste site implicará na aceitação dos termos aqui descritos. Esta declaração pode ser atualizada de tempos em tempos, consulte esta página regularmente. A última linha indica a data da última atualização.</p>



            <p><strong>Qual é o propósito e o escopo desta política?</strong></p>



            <p>O Benefício Social Familiar se compromete em proteger as informações pessoais que coletamos através deste site. Consequentemente, o Benefício Social Familiar desenvolveu esta política para descrever como e quais tipos de dados serão coletados dos Usuários do site ou durante as relações de negócio e os fins para os quais pode coletar, compartilhar ou divulgar os dados. O Benefício Social Familiar também descreve as precauções que são tomadas para manter seus dados seguros.</p>



            <p>Esta política se aplica a qualquer informação obtida pelo Benefício Social Familiar por meio de uso do seu site. Não é aplicável a nenhum site da Internet controlado por terceiros que, por algum motivo de negócio podem estar ser vinculados ou associados ao site oficial do Benefício Social Familiar ou de sua rede de prestadores de serviços.</p>



            <p>Os termos desta política estão sujeitos a quaisquer termos adicionais de responsabilidade ou outros termos que você tenha celebrado com o Benefício Social Familiar, tais como, como declarações ou avisos de privacidade do cliente, e quaisquer leis e regulamentos obrigatórios aplicáveis.</p>



            <p><strong>Quais informações coletamos e para quais propósitos?</strong></p>



            <p>Quando você visita o site do Benefício Social Familiar, nosso servidor da Web grava automaticamente detalhes sobre sua visita (por exemplo, seu endereço IP, a localização da qual você nos visita, o tipo de software do navegador usado, as páginas do site que você visitou, incluindo o data e duração da sua visita).</p>



            <p>O Benefício Social Familiar usará seus dados para o estrito cumprimento da prestação dos benefícios.</p>



            <p>O Benefício Social Familiar respeita as leis e regulamentos aplicáveis na utilização de dados pessoais.</p>



            <p><strong>A quem divulgamos as informações coletadas no site?</strong></p>



            <p>O Benefício Social Familiar pode divulgar suas informações a seus empregados, entidades sindicais e sua rede de prestadores de serviços, exclusivamente para realizar os serviços de prestação de benefícios sociais para os quais fora contratado. Nossos agentes, as entidades sindicais e rede de prestadores de serviços que tiverem acesso a dados pessoais obtidos através do site são obrigados a respeitar a privacidade de acordo com as normas do Benefício Social Familiar.</p>



            <p>Ao transferir dados pessoais nós nos certificamos de que cumpramos as leis e regulamentos aplicáveis, por exemplo, ao celebrar acordos que assegurem que os destinatários de suas informações mantenham um nível adequado de proteção de dados.</p>



            <p>Também podemos divulgar suas informações a agências ou entidades governamentais, autoridades reguladoras ou outras pessoas de acordo com qualquer lei, regulamento, ordem judicial, pedido oficial, ou sob qualquer diretriz emitida por autoridades reguladoras ou outras, ou processos semelhantes que sejam exigidos ou permitidos pela lei aplicável.</p>



            <p><strong>Que medidas de segurança implementamos para proteger suas informações coletadas através do site do Benefício Social Familiar?</strong></p>



            <p>O Benefício Social Familiar implementou medidas de segurança técnicas e organizacionais para proteger seus dados pessoais coletados do site contra acesso não autorizado, uso indevido, perda ou destruição.</p>



            <p><strong>Como tratamos as mensagens eletrônicas?</strong></p>



            <p>Todas as mensagens eletrônicas enviadas e recebidas pelo Benefício Social Familiar são protegidas por medidas técnicas e organizacionais e só podem ser acessadas em casos justificados, de acordo com as leis e regulamentos aplicáveis (por exemplo, ordem judicial, suspeita de conduta criminal, violação de obrigações regulatórias, ) a pessoas específicas em funções definidas (por exemplo, Legal, Privacidade). Cada passo do processo, bem como, os critérios de pesquisa usados, são registrados em uma trilha de auditoria e todos os e-mails são descartados depois que o período de retenção aplicável expirou.</p>



            <p><strong>O que você deve considerar ao enviar dados pela Internet?</strong></p>



            <p>A Internet geralmente não é considerada como um ambiente seguro e as informações enviadas pela Internet (seja pelo site ou via mensagem eletrônica) podem ser acessadas por terceiros não autorizados, levando potencialmente a divulgações, falhas técnicas ou mudanças de conteúdo. Mesmo que o remetente e o receptor estejam localizados no território nacional, as informações enviadas pela Internet podem ser transmitidas através das fronteiras internacionais e serem encaminhadas para um país com menor nível de proteção de dados do que existe no seu país de residência.</p>



            <p>Por favor, note que não aceitamos qualquer responsabilidade pela segurança de suas informações enquanto estiverem em trânsito pela Internet para o Benefício Social Familiar. Para proteger sua privacidade, gostaríamos de lembrá-lo de que você pode escolher outro meio de comunicação com o Benefício Social Familiar quando o julgar apropriado.</p>



            <p><strong>Como você pode acessar ou revisar seus dados pessoais?</strong></p>



            <p>Quando permitido pela lei ou regulamento aplicável, você pode:</p>



            <p>• verificar se mantemos seus dados pessoais,</p>



            <p>• solicitar revogação de um consentimento anterior,</p>



            <p>• solicitar uma cópia dos seus dados pessoais,</p>



            <p>• solicitar que corrijamos qualquer um de seus dados pessoais que sejam imprecisos, ou</p>



            <p>Todos os pedidos relacionados a dados pessoais devem ser feitos através dos canais oficiais de comunicação do Benefício Social Familiar disponíveis no menu contato.</p>



            <p>Estou ciente e autorizo que os dados por mim informados nesta plataforma digital, sejam utilizados pelas entidades para a estrita prestação dos benefícios a que minha empresa, meus trabalhadores e seus familiares têm direito, nos termos do artigo 11 e seguintes da Lei Federal n. 13.709/2018, e as demais que tratam deste assunto e vierem a substituí-la.</p>



            <p>Atualizado em&nbsp;<strong>28/10/2020</strong>&nbsp;&#8211; versão&nbsp;<strong>1.00 / 2020</strong></p>
            <div className="post-tags">
            </div>
        </div>


    </div>
}

export default LGPD