import React from "react"
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom"
import Home from "./pages/home"
import Cadastro from "./pages/cadastro"
import EsqueceuPAss from "./pages/forgotpass"

const Route = () =>{
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/cadastro-operador",
            element: <Cadastro />,
        },
        {
            path: "/esqueceu-senha",
            element: <EsqueceuPAss />,
        },
    ])

    return <>
        <RouterProvider router={router} />
    </>
}

export default Route