import React, {useState} from "react"
import { Form, Input, message, Row, Col, Button,Typography, Tooltip} from "antd"
import CpfCnpj from "@phpenterprise/cpf-cnpj-mask"
import config from 'config/config'
import {checkDoc, getWindowParams, generateToken} from '../../helper'



// images
import LOGO from "../../assets/img/logo.png"
import NOVOUSUARIOSM from "../../assets/img/novo_usuario-150x150.png"
import ICONAVANCAR from "../../assets/img/icon-avancar-150x150.png"
import ESQUECEU from "../../assets/img/esqueceu-150x150.png"


const { Title, Paragraph, Text, Link} = Typography;

const Login = () => {
    let url = "https://api.bsfonline.com.br"
    const [formetapa1] = Form.useForm()
    const [formetapa2] = Form.useForm()
    const Item = Form.Item
    const [cnpj, setCnpj] = useState("")
    const [cpf, setCpf] = useState("")
    const [senha, setSenha] = useState("")
    const [etapa, setEtapa] = useState("etapa1")
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [cpfCnpj2, setCpfCnpj2] = useState("");
    const [mask, setMask] = useState("");
    const [loading, setLoading] = useState(false)

    const etapas = () => {
        if(etapa === "etapa1"){
            return etapa1()
        }else if(etapa === "etapa2"){
            return etapa2()
        }
    }

    const eventos = (e) => {
        if(etapa === "etapa1"){
            fetchEpata1()
        }else if(etapa === "etapa2"){
            fetchEpata2()
        }
    }



    const fetchEpata1 = async (e) => {
        setLoading(true)
        try{
            let response = await fetch(`${config[process.env.NODE_ENV].url}/bsf-direto/check-empresa`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cnpj: e.cnpj
                })
            })

            if( !response.ok ){
                let json = await response.json()
                // message.error( json.message ) //frase de resposta mudou
                message.error( "CNPJ ou CPF não localizado")
                setLoading(false)
                return false
            }

            setEtapa("etapa2")
            return true
        }catch(e){
            console.log(e)
            message.error("Problemas de comunicação com o servidor.")
        }finally{
            setLoading(false)
        }
    }

    const fetchEpata2 = async (e) => {
        setLoading(true)
        try{
            let response = await fetch(`${config[process.env.NODE_ENV].url}/bsf-direto/login-operador`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    senha: e.senha,
                    cpf: e.cpf.replace(/\D/g,''),
                    cnpj: formetapa1.getFieldValue("cnpj"),
                })
            })

            if( !response.ok ){
                let json = await response.json()
                if(json.message == 'Operador não encontrada.'){
                    message.error( 'CPF do operador não encontrado.' ) //A pedido do chamado é pra exibir isso.
                } else 
                if(json.message == 'Senha Invalida.'){
                    message.error( 'Usuário ou senha invalido!' ) //pra não deixar implicito que o usuario existe realmente.
                } else
                {
                    message.error( json.message )
                }
                return false
            }
            let hash = btoa(`bsfdireto|${formetapa1.getFieldValue("cnpj").replace(/\D/g,'')}|${e.cpf.replace(/\D/g,'')}|${e.senha}`)

            message.success("Usuário Autenticado com sucesso, Aguarde.", 8000)
            formetapa1.resetFields()
            formetapa2.resetFields()
            setEtapa("etapa1")

            //redirect
            window.location.href = "https://www.beneficiosocial.com.br/login-bsfdireto-empregador?token=" + hash

            // setEtapa("etapa2")
            return true
        }catch(e){
            message.error("Problemas de comunicação com o servidor.")
        }finally{
            setLoading(false)
        }
    }

    const etapa1 = () => {
        return <Row gutter={16} align={"middle"}>
            <Col xs={24} sm={24} md={8} lg={12} xl={15} xxl={15} align="right">
                <Title className="title-login" level={4} style={{ marginTop: '6px' }}>
                    Faça seu login
                </Title>
            </Col>
            <Col xs={24} sm={24} md={16} lg={12} xl={9} xxl={9} align="right">
                <div className="login etapa1">
                    <Form
                        form={formetapa1}
                        onFinish={fetchEpata1}
                        initialValues={{
                            // cnpj: getWindowParams().cnpj,
                        }}
                    >
                        <Row gutter={16}>
                            <Col xs={15} sm={15} md={18} lg={17} xl={21} xxl={21} align="right">
                                <Form.Item
                                    className="form-control"
                                    name="cnpj"
                                    // label={ <> CNPJ Empresa </> }
                                    rules={[
                                        { required: true   , message: "CPF/CNPJ é requerido."},
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                let check = checkDoc(getFieldValue('cnpj'),['cpf','cnpj'])
                                                if ( !value || typeof check !== 'string' ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(check));
                                            },
                                        }),
                                    ]}
                                >
                                    <CpfCnpj className="ant-input ant-input-outlined" placeholder="Digite o CPF ou CNPJ"   />
                                </Form.Item>
                            </Col>
                            <Col xs={9} sm={9} md={6} lg={7} xl={3} xxl={3} align="right">
                                <Form.Item className="text-right form-control" >
                                    <Tooltip title="Continuar">
                                        <Button htmlType="submit" shape="circle"><img width={'28px'} src={ICONAVANCAR}></img></Button>
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Col>
        </Row>
    
        return <div>
            <CpfCnpj
                placeholder="Digite um CPF ou CNPJ"
                type="tel"
                disabled={loading}
                value={cpfCnpj}
                className="cnpj_input"
                onKeyPress={(e) => {
                    if(e.charCode === 13){
                        eventos(e)
                    }
                }}
                onChange={(event, type) => {
                    if( type === "CNPJ"){
                        setCnpj(event.target.value.replace(/\D/g, ""))
                    }
                    setCpfCnpj(event.target.value);
                }}
            />
        </div>
    }

    const etapa2 = () => {
        return (
            <Row gutter={16} align={"middle"}>
                <Col xs={24} sm={24} md={8} lg={12} xl={15} xxl={15} align="right">
                    <Title className="title-login" level={4} style={{ marginTop: '6px' }}>
                        Informe o usuário
                    </Title>
                </Col> 
                <Col xs={24} sm={24} md={16} lg={12} xl={9} xxl={9} align="right">
                    <div className="login etapa2">
                        <Form form={formetapa2} onFinish={fetchEpata2}>


                            <Row gutter={16}>
                                <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                                    <Form.Item
                                        className="form-control"
                                        name="cpf"
                                        // label="CPF"
                                        rules={[
                                            { required: true, message:"CPF é requerido."},
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                let check = checkDoc(getFieldValue('cpf'),['cpf'])
                                                if ( !value || typeof check !== 'string' ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(check));
                                                },
                                            }),
                                        ]}
                                    >
                                        <CpfCnpj className="ant-input ant-input-outlined" maxLength={14} placeholder="CPF Usuário"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={9} md={9} lg={9} xl={9} xxl={9}>
                                    <Form.Item
                                        className="form-control"
                                        name="senha"
                                        // label="Senha"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Informe a senha.',
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder="Senha de Acesso"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6} align="right">
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item className="text-right form-control" >
                                                <Tooltip title="Continuar">
                                                    <Button htmlType="submit" shape="circle"><img width={'28px'} src={ICONAVANCAR}></img></Button>
                                                </Tooltip>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Tooltip title="Criar um novo usuário">
                                                <Link href={`/cadastro-operador` + `?cnpj=${formetapa1.getFieldValue("cnpj").replace(/\D/g,'')}`}>
                                                    <Button htmlType="button" shape="circle">
                                                        <img width={'28px'} src={NOVOUSUARIOSM}></img>
                                                    </Button>
                                                </Link>
                                            </Tooltip>
                                        </Col>
                                        <Col span={8}>
                                            <Tooltip title="Solicitar nova senha">
                                                <Link href={`/esqueceu-senha` + `?cnpj=${formetapa1.getFieldValue("cnpj").replace(/\D/g,'')}`+ `&cpf=${formetapa2.getFieldValue("cpf") ? formetapa2.getFieldValue("cpf").replace(/\D/g,'') : ""}`}>
                                                    <Button htmlType="button" shape="circle">
                                                        <img width={'28px'} src={ESQUECEU}></img>
                                                    </Button>
                                                </Link>
                                            </Tooltip>
                                        </Col>
                                    </Row>


                                </Col>
                            </Row>
                        </Form>
                </div>
                </Col>
            </Row>
        )
        // return <div className="operador">
        //     <CpfCnpj
        //         placeholder="Digite um CPF do operador"
        //         type="tel"
        //         disabled={loading}
        //         maxLength={14}
        //         value={cpfCnpj2}
        //         className="cnpj_input cpf"
        //         onChange={(event, type) => {
        //             if( type === "CPF"){
        //                 setCpf(event.target.value.replace(/\D/g, ""))
        //             }

        //             setCpfCnpj2(event.target.value);
        //         }}
        //     />
        //     <input
        //         className="cnpj_input senha"
        //         type="password"
        //         placeholder="Digite sua Senha"
        //         onChange={(e) => {
        //             setSenha(e.target.value)
        //         }}
        //         onKeyPress={(e) => {
        //             if(e.charCode === 13){
        //                 eventos(e)
        //             }
        //         }}
        //     />
        // </div>
    }

    return <div className="login">
        <div className="wrapper">
            <Row gutter={10} align={"middle"}>
                {/* <Col xs={6} sm={6} md={18} lg={18} xl={18} xxl={18} align="right">
                    <div className="texto">
                        <p><span>Faça seu login</span></p>
                    </div>
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="right">
                    <div className="etapas">
                        {etapa === "etapa1" ? etapa1() : ''}
                        {etapa === "etapa2" ? etapa2() : ''}
                    </div>
                </Col>
                {/* <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} align="right">
                    <Row gutter={10} align={"right"}> */}
                        {/* <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18} style={{textAlign: 'right'}}>
                            <button onClick={(e) => eventos(e)} className="btn-small" disabled={loading}>Enviar</button>
                        </Col> */}
                        {/* <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} align="right" className="login-new-operador">
                            {etapa == 'etapa2' && cpfCnpj.replace(/\D/g,"").length == 14 ?
                                <a href={`/cadastro-operador` + (cpfCnpj ? "?cnpj=" + cpfCnpj : "")} style={{ lineHeight: '0px' }}>
                                    <img width={'28px'} src={NOVOUSUARIOSM}></img>
                                </a>
                            : ""}
                        </Col> */}
                    {/* </Row>
                </Col> */}
            </Row>
            <div className="text-right">


            </div>
        
        </div>
    </div>
}

export default Login