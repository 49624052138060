import React from "react"
import { Select, notification } from "antd"
import { Typography } from "antd"
import * as Icons from '@ant-design/icons'
import config from "../config/config"
import { DateTime } from 'luxon'
import moment from "moment"

const { Option } = Select


const yearsRange = (range, plus = null, order = "asc") => {
    const years = []
    let now = new Date().getUTCFullYear()
    // eslint-disable-next-line for-direction
    for (let i = now + (plus || 1); i > now - range; i--) { years.push(i) }
    if (order === "asc") {
        return years.sort()
    }
    return years
}


const checkDoc = (doc, type = []) => {
    if(!doc) return false
    doc = doc.replace(/\D/g, "")
    if (type.includes("cnpj") && doc.length == 14) {
        if (!checkCNPJ(doc)) {
            return "CNPJ inválido!"
        }
        return true
    } else
    if (type.includes("cpf") && doc.length == 11) {
        if (!checkCPF(doc)) {
            return "CPF inválido!"
        }
        return true
    }
    if (type.length) {
        let tiposDoc = ""
        type.map((e) => { if (tiposDoc === "") { tiposDoc += e } else { tiposDoc += "/" + e } })
        return `Insira um documento válido! (${tiposDoc.toUpperCase()})`
    }
    return false
}

const checkCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[\D]+/g, '');

    //remove o erro chato quando nao se digita nenhum valor no campo.
    if (cnpj.length < 1) {
        return true;
    }

    if (cnpj === '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

const checkCPF = (strCPF) => {
    strCPF = strCPF.replace(/\D/g, '');
    let Soma;
    let Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;
    if (strCPF == "11111111111") return false;
    if (strCPF == "22222222222") return false;
    if (strCPF == "33333333333") return false;
    if (strCPF == "44444444444") return false;
    if (strCPF == "55555555555") return false;
    if (strCPF == "66666666666") return false;
    if (strCPF == "77777777777") return false;
    if (strCPF == "88888888888") return false;
    if (strCPF == "99999999999") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
}

const getCEP = async (cep) => {
    cep = `${cep}`.replace(/D/g, "");
    if (cep.length != 8) {
        return false;
    }

    // Utilizamos o webservice "viacep.com.br" para buscar as informações do CEP fornecido pelo usuário.
    // A url consiste no endereço do webservice ("http://viacep.com.br/ws/"), mais o cep que o usuário
    // informou e também o tipo de retorno que desejamos, podendo ser "xml", "piped", "querty" ou o que
    // iremos utilizar, que é "json"
    var url = "https://viacep.com.br/ws/" + cep + "/json";

    // Aqui fazemos uma requisição ajax ao webservice, tratando o retorno com try/catch para que caso ocorra algum
    // erro (o cep pode não existir, por exemplo) o usuário não seja afetado, assim ele pode continuar preenchendo os campos
    let dados = await fetch(url, { method: "GET", dataType: "jsonp", })
    dados = await dados.json()
    return await dados
}


/**
 * MASK suports
 * @param string 'date' @returns DATE
 * @param string 'tel @return based on lenght a mask is created
 * @param string '*'  => middle of sentence is changed to *
 * @param string null =>  CNPJ or CPF mask 
 * @else string type =>  type is used to mask
 * @return string masked
 * 
 */
const mask = (data, type = null) => {
    try {

        if (!data) return ""
        

        if (type == "currency") {
            var formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
            return formatter.format(parseFloat(data)); /* R$ 2.500,00 */
        }

        if (type == "date") {
            if(data.length == 10){
                return DateTime.fromFormat(data,'yyyy-MM-dd').toFormat('dd/MM/yyyy') 
            }
            return DateTime.fromISO(data).setLocale('pt-br').toLocaleString() //.toFormat('dd/LL/y')
            
        }
        if (type == "datetime") {
            return  DateTime.fromISO(data).setLocale('pt-br')/*.toLocaleString()*/.toFormat('dd/LL/y hh:mm:ss')
        }
        
        if(type === "tel"){
            let count = data.replace(/\D/g,"").length
            if(count === 8) type = "####-####"
            if(count === 9) type = "#####-####"
            if(count === 10) type = "(##) ####-####"
            if(count === 11) type = "(##) #####-####"
        }

        if (type == "cep") {
            return data.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3") // '13.467-692'
        }

        if(type == "*"){
            let count = data.length
            let cutSize = parseInt( count/3  )
            let maskared = '';
            for(let i = 0; i <= (count)-1; i++) {
                if(i < cutSize || i > count-cutSize){
                    maskared += data[i]
                } else {
                    maskared += "*"
                }
            }
            return maskared
        }


        if( !type ){
            data = data.replace(/\D/g,'')
            let count = data.length
            if( count === 14 ){
                type = "##.###.###/####-##"
            } else
            if( count === 11 ){
                type = "###.###.###-##"
            } 
        }

        let maskared = '';
        let k = 0;
        if (type) {

            for (let i = 0; i <= (type.length) - 1; i++) {
                if (type[i] == '#') {
                    if (data[k]) maskared += data[k++];
                }
                else {
                    if (type[i]) maskared += type[i];
                }
            }
            return maskared;
        }
        
        return data

    } catch (error) {
        return data
    }


}

const uuidv4 = ( mask = null ) =>{
    if(!mask) mask = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    return mask.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
  }


const ConvertToMonetary = (number = "0.00") => {
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
    return formatter.format(parseFloat(number)); /* R$ 2.500,00 */
}


// Function: createNestedObject( base, names[, value] )
const createNestedObject = function( base, names, value ) {
    // If a value is given, remove the last name and keep it for later:
    var lastName = arguments.length === 3 ? names.pop() : false;

    // Walk the hierarchy, creating new objects where needed.
    // If the lastName was removed, then the last object is not set yet:
    for( var i = 0; i < names.length; i++ ) {
        base = base[ names[i] ] = base[ names[i] ] || {};
    }

    // If a value was given, set it to the last name:
    if( lastName ) base = base[ lastName ] = value;

    // Return the last object in the hierarchy:
    return base;
};


const copiable = function (copiable, children = null){
    return <Typography.Text copyable={{
        icon: [<Icons.CopyFilled/>, <Icons.ExclamationCircleFilled/>],
        tooltips: ['Copiar', 'Copiado!!'],
        text: copiable
    }}>
        {children ? children : copiable}
    </Typography.Text>
}


const getWindowParams = function(){
    if(window.location.search){
        let URLparams = {}
        window.location.search.replace('?','').split('&').map(e => { e = e.split('='); Object.assign(URLparams,{ [e[0]]:e[1] }) })
        Object.keys(URLparams).map(e => {
            if(moment(URLparams[e],'DD/MM/YYYY').isValid()){
                URLparams[e] = moment(URLparams[e],'DD/MM/YYYY')
            }
        })
        return URLparams
    }
    return {}
}

const generateToken = () => { return "Basic " + window.btoa("bsfdireto:BfO45fT7U"); }


/**
     * 
     * @param {*} cnpj 
     * @returns boolean 
     */
const checkCnpjExist = async (cnpj) => {
    try{
        let response = await fetch(`${config[process.env.NODE_ENV].url}/bsf-direto/check-empresa`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "cnpj": cnpj
            })
        })

        if( !response.ok ){
            let json = await response.json()
            return "CNPJ ou CPF não localizado"
        }
        let json = await response.json()
        return true
    }catch(e){
        return "Problemas de comunicação com o servidor."
    }
}

export {
    yearsRange,             //Gera Options para select: usage: yearsRange(range,  plus = null, order = "asc"
    checkDoc,               // checa o documento com base em um array enviado. usage: heckDoc(doc, ['cnpj', 'cpf']);
    checkCPF,               // retorna true/false. usage: checkCPF(doc);
    checkCNPJ,              // retorna true/false. usage: checkCPF(doc);
    getCEP,                 // retorna dados do endereço com base em CEP válido usando VIACEP. usage: CEP(numero_CEP);
    mask,                   // retorna o documento já com a mascara usage: mask(doc, (optional)tipo )
    uuidv4,                 // Geradore de valor unico
    ConvertToMonetary,      // returna como monetary
    createNestedObject,     // Converting ARRAY from formdata to JSON OBJECT
    copiable,               // set as copiable
    getWindowParams,        // PEga os dados do window location e convert em object
    generateToken,           //
    checkCnpjExist
}